<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Inquiry</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Inquiry
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('enquiry', 'create')"
                       @click="createLeadCategory()"
                       class="mx-2 btn btn-standard"
                  >
                      Lead category
                  </v-btn>
                  <v-btn v-if="checkIsAccessible('enquiry', 'create')"
                      @click="createEnquiry()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add inquiry
                  </v-btn>

                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      label="Search by name, email or contact"
                      v-model="search.name"
                      v-on:keyup.enter="searchEnquiry"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                          v-model="search.priority"
                          :items="priorities"
                          item-text="name"
                          item-value="value"
                          label="Priority"
                          outlined
                          dense
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Status"
                      v-model="search.status"
                      v-on:keyup.enter="searchEnquiry"
                      :items="statuses"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      class="btn btn-primary"
                      @click="searchEnquiry"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('full_name')" @click="sortBy('full_name')">Full Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Gender</th>
                  <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                  <th>Priority</th>
                  <th>Follow up</th>
                  <th>Status</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(enquiry, index) in sortedItems" :key="index" >
                    <td>
                      <a @click="editEnquiry(enquiry.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ enquiry.full_name }}
                      </a>
                    </td>
                    <td>
                      {{ enquiry.email ? enquiry.email : 'N/A' }}
                    </td>
                    <td>
                      {{ enquiry.phone ? enquiry.phone : 'N/A'}}
                    </td>
                    <td>
                      {{ enquiry.gender_text }}
                    </td>
                    <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">
                      {{ enquiry.branch_name }}
                    </td>
                    <td>
                      {{ enquiry.priority_text }}
                    </td>
                    <td>
                      <span class="badge `"
                            v-bind:class="{ 'badge-success': enquiry.requires_followup, 'badge-danger': !enquiry.requires_followup }"
                      >{{ enquiry.requires_followup ? 'Yes' : 'No' }}</span>
                       <span class="mt-2"></span> {{ enquiry.formatted_follow_up_required_date ?enquiry.formatted_follow_up_required_date : ''}}
                    </td>
                    <td>
                      {{ enquiry.status_text ? enquiry.status_text : 'NA' }}
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('enquiry', 'show')">
                                <a @click="quickView(enquiry)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                </span>
                                  <span class="navi-text">Quick view</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('enquiry', 'edit')">
                                <a @click="editEnquiry(enquiry.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-edit</v-icon>
                                </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item" v-if="enquiry.requires_followup && checkIsAccessible('enquiry', 'edit')">
                                <a @click="editEnquiryFollowup(enquiry.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-edit</v-icon>
                                </span>
                                  <span class="navi-text">Enquiry Follow Up</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('enquiry', 'delete')">
                                <a class="navi-link" @click.prevent="deleteEnquiry(enquiry.id)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="10" class="text-center">No Data Found</td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="enquiries.length > 0"
                  class="pull-right mt-7"
                  @input="getAllEnquiries"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>

          </div>
        </div>
      </div>
    </div>
    <quick-view-enquiry ref="quick-view"></quick-view-enquiry>
  </v-app>
</template>

<script>
import EnquiryService from "@/services/enquiry/EnquiryService";
import QuickViewEnquiry from "@/view/pages/view/enquiry/QuickViewEnquiry";

const enquiry = new EnquiryService();
export default {
  name: "Index",
  components: {QuickViewEnquiry},
  data() {
    return {
      loading: false,
      total: null,
      perPage: null,
      page: null,
      enquiries: [],
      search:{
        name: '',
        address: '',
        phone: '',
        priority: '',
        is_active: ''
      },
      sort: {
        key: '',
        isAsc: false
      },
      priorities: [
        { name: 'Low', value: 'low' },
        { name: 'Medium', value: 'medium' },
        { name: 'High', value: 'high' }
      ],
      statuses: [
        {name: 'Open', value: 'open'},
        {name: 'Closed', value: 'closed'},
      ],
    }
  },
  mounted(){
    this.getAllEnquiries();
  },
  computed: {
    sortedItems() {
      const list = this.enquiries.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllEnquiries() {
      enquiry
          .paginate(this.search, this.page)
          .then((response) => {
            this.enquiries = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchEnquiry() {
      this.getAllEnquiries();
    },

    createEnquiry() {
      this.$tabs.open({name: "enquiry-create"});
    },

    editEnquiry(enquiryId) {
      this.$tabs.open({name: "enquiry-edit", params: { enquiryId: enquiryId }});
    },

    quickView(enquiry) {
      this.$refs['quick-view'].openDialog(enquiry)
    },

    createLeadCategory() {
      this.$router.push({name: "lead-category"});
    },

    editEnquiryFollowup(enquiryId) {
      this.$router.push({
        name: 'followup',
        params: {enquiryId:enquiryId},
      })
    },

    deleteEnquiry(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            enquiry
                .delete(id)
                .then((response) => {
                  this.getAllEnquiries()
                  this.$snotify.success("Enquiry Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      });
    },
  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>