<template>
  <v-dialog
      max-width="800"
      scrollable
      v-model="dialog"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Quick view enquiry</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <b>Full name: </b>{{enquiry.full_name}}
          </v-col>

          <v-col cols="12" md="4">
            <b>Email: </b>{{enquiry.email}}
          </v-col>

          <v-col cols="12" md="4">
            <b>Address: </b>{{enquiry.address || 'N/A'}}
          </v-col>

          <v-col cols="12" md="4">
            <b>Phone: </b>{{enquiry.phone}}
          </v-col>

          <v-col cols="12" md="4">
            <b>Category: </b>{{enquiry.category || 'N/A' }}
          </v-col>

          <v-col cols="12" md="4">
            <b>Purpose for visit: </b>{{enquiry.purpose_for_visit || 'N/A' }}
          </v-col>

          <v-col cols="12" md="4">
            <b>Current visa: </b>{{enquiry.visa_type || 'N/A'}}
          </v-col>

          <v-col cols="12" md="4">
            <b>Status: </b>
            <span class="badge text-lg" v-bind:class="{ 'badge-info': enquiry.status == 'open', 'badge-secondary': enquiry.status != 'open' }">
              {{ enquiry.status_text }}
            </span>
          </v-col>

          <v-col cols="12" md="4">
            <b>Priority: </b>
            <span>
              {{ enquiry.priority_text }}
            </span>
          </v-col>

          <v-col cols="12" md="4">
            <b>Description: </b>
            <span v-html="enquiry.description">
            </span>
          </v-col>

          <v-col cols="12" md="4" v-if="enquiry.requires_followup">
            <b>Followup date: </b>
            <span>
              {{ enquiry.formatted_follow_up_required_date }}
            </span>
          </v-col>

          <v-col cols="12" md="4" v-if="enquiry && enquiry.attachment_paths && enquiry.attachment_paths.length > 0">
            <b>Attachments: </b>
            <ul >
              <li class="ml-5" v-for="(item, index) in enquiry.current_visas" :key="index">
                <a :href="item.real"> Attachment {{ index + 1 }}</a>
              </li>
            </ul>
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            @click="closeDialog"
            color="mr-2" text dark
            medium
        >
          Cancel
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "QuickViewEnquiry",
  data() {
    return {
      dialog: false,
      enquiry: {},
    }
  },
  methods: {
    openDialog(item) {
      this.dialog = true;
      this.enquiry = item;
    },
    closeDialog() {
      this.enquiry = {};
      this.dialog = false;
    },
  }
}
</script>